import React from 'react';

import {graphql} from 'gatsby';
import {Header, Perks, Press, Roles, Story, Team, Values} from 'src/components/careers';
import {Box, SEOWithQuery} from 'src/components/shared';
import * as paths from 'src/routes/paths';
import {seo} from 'src/utils/copy';

import {SanityBenefitsOrStoryOrTestimonialsOrValues, SanityCareers} from 'graphql-types';

export const query = graphql`
  query CareersPageData {
    sanityCareers {
      heading
      content
      buttonText
      jobsSectionTitle
      sections {
        ... on SanityBenefits {
          _key
          _type
          title
          description
          values {
            _key
            title
            description
          }
        }
        ... on SanityStory {
          _key
          _type
          title
          content1
          content2
          image1 {
            asset {
              url
            }
          }
          image2 {
            asset {
              url
            }
          }
        }
        ... on SanityTestimonials {
          _key
          _type
          title
          members {
            _key
            title
            name
            quote
            icon {
              asset {
                url
              }
            }
          }
        }
        ... on SanityValues {
          _key
          _type
          title
          description
          values {
            _key
            value
            number
          }
        }
      }
    }
  }
`;

enum Sections {
  values = 'values',
  story = 'story',
  benefits = 'benefits',
  testimonials = 'testimonials',
}

const getSection = ({
  _key,
  ...sectionProps
}: SanityBenefitsOrStoryOrTestimonialsOrValues): Record<string, JSX.Element> => ({
  [Sections.values]: <Values key={_key} boxProps={{id: paths.careerAnchorPaths.values}} {...sectionProps} />,
  [Sections.story]: <Story key={_key} boxProps={{id: paths.careerAnchorPaths.story}} {...sectionProps} />,
  [Sections.benefits]: <Perks key={_key} boxProps={{id: paths.careerAnchorPaths.perks}} {...sectionProps} />,
  [Sections.testimonials]: <Team key={_key} boxProps={{id: paths.careerAnchorPaths.team}} {...sectionProps} />,
});

type Props = {
  data: {
    sanityCareers: SanityCareers;
  };
};

const Careers: React.FC<Props> = ({data: {sanityCareers: data}}) => {
  return (
    <Box backgroundColor="white">
      <Header {...data} />
      <Press />

      {data?.sections?.map(section => (section && section._type ? getSection(section)[section._type] : null))}

      {data?.jobsSectionTitle && <Roles sectionTitle={data.jobsSectionTitle} id={paths.careerAnchorPaths.roles} />}
      <SEOWithQuery title={seo.careersTitle} />
    </Box>
  );
};

export default Careers;
