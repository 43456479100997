import React from 'react';

import {Box, Flex, Image, BoxProps} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';
import styled from 'styled-components/macro';

const {H5, P0} = Typography;

const Wrapper: typeof Box = styled(Box).attrs({
  mx: 'auto',
  p: '32px',
  height: '100%',
  backgroundColor: 'white',
  borderRadius: '8px',
  boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08);',
})``;

const ImageWrapper: typeof Box = styled(Box).attrs({
  height: '64px',
  width: '64px',
  mr: '16px',
})`
  font-size: 12px;
`;

const Divider: typeof Box = styled(Box).attrs({
  borderBottom: '1px solid',
  borderColor: 'lineGray',
  opacity: 0.3,
})``;

const StyledImage: typeof Image = styled(Image)`
  object-fit: cover;
  height: 100%;
  border-radius: 100%;
`;

type TestimonialProps = BoxProps & {name: string; title: string; quote: string; image: string};

export const Testimonial: React.FC<TestimonialProps> = ({name, title, quote, image, ...props}) => {
  return (
    <Wrapper {...props}>
      <Box mb={'24px'}>
        <Flex alignItems={'center'} mb={'24px'}>
          <ImageWrapper>
            <StyledImage src={image} alt={name} />
          </ImageWrapper>
          <Box>
            <P0>{name}</P0>
            <P0 fontWeight={'normal'}>{title}</P0>
          </Box>
        </Flex>
        <Divider />
      </Box>
      <H5 color="darkGreen" fontSize={['1.375rem']} lineHeight={['1.6rem', null, '1.75rem', null]}>
        {quote}
      </H5>
    </Wrapper>
  );
};
