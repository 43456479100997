import React from 'react';

import {SectionLayout} from 'src/components/careers';
import {Box, Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';
import {Icon, Icons} from 'src/svgs';
import * as careerCopy from 'src/utils/copy';
import styled from 'styled-components/macro';

const {H5} = Typography;

const BlockWrapper = styled(Flex).attrs({
  flexDirection: ['column', null, null, null, 'row'],
})``;

const IconWrapper = styled(Box).attrs({
  flexShrink: 0,
})``;

const StyledTitle = styled(H5).attrs({
  color: 'sand',
  ml: ['0', null, null, null, '25px'],
  mt: ['16px', null, null, null, '0'],
})``;

export const Press: React.FC = () => {
  const copy = careerCopy.careers.press;
  return (
    <Box backgroundColor="darkGreen">
      <SectionLayout
        leftContent={
          <BlockWrapper>
            <IconWrapper width="172px" height="27px" transform={['none', null, null, 'translateY(8px)']}>
              <Icon aria-label={copy.fastCompany} icon={Icons.fastCompany} color="sand" />
            </IconWrapper>
            <StyledTitle>{copy.fastCompany}</StyledTitle>
          </BlockWrapper>
        }
        leftWrapperProps={{
          mb: ['24px', null, null, '0px'],
        }}
        rightContent={
          <BlockWrapper>
            <IconWrapper width="107px" transform={['none', null, null, 'translateY(5px)']}>
              <Icon aria-label={copy.linkedIn} icon={Icons.linkedInFull} color="sand" />
            </IconWrapper>
            <StyledTitle>{copy.linkedIn}</StyledTitle>
          </BlockWrapper>
        }
      />
    </Box>
  );
};
