import React from 'react';

import {Events} from 'src/analytics';
import {RolesDepartment} from 'src/components/careers';
import {ExternalAnchor} from 'src/components/controls/Anchor';
import {Box, Flex, LayoutGrid, Loading, Section, Select, BoxProps} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';
import {useFetchGreenhousePositions} from 'src/hooks/useFetchGreenhousePositions';
import * as externalUrls from 'src/routes/externalUrls';
import * as careerCopy from 'src/utils/copy';
import styled from 'styled-components/macro';

const {H3, P0} = Typography;

const Wrapper = styled(Section).attrs({})``;

const StyledAnchor = styled(ExternalAnchor).attrs({
  maxWidth: '300px',
})``;

const ALL_DEPARTMENTS = 'All Departments';

export const Roles: React.FC<BoxProps & {sectionTitle: string}> = ({sectionTitle, ...boxProps}) => {
  const copy = careerCopy.careers.roles;
  const handleTrackEvent = (item: string) => () => {
    Events.didClickNav({item, pageLocation: 'hero'});
  };
  const {data, loading, error} = useFetchGreenhousePositions();
  const allDepartments = data.filter(department => department.jobs?.length > 0);
  const [selectedDepartmentName, setSelectedDepartmentName] = React.useState<string | null>(null);
  const selectedDepartment = allDepartments.find(department => department.name === selectedDepartmentName);
  const departments = selectedDepartment ? [selectedDepartment] : allDepartments;
  const onSelectDepartment: React.ChangeEventHandler<HTMLSelectElement> = e => {
    const {value} = e.target;
    if (value === ALL_DEPARTMENTS) {
      setSelectedDepartmentName(null);
    } else {
      setSelectedDepartmentName(value);
    }
  };

  return (
    <Wrapper backgroundColor="white" pt={['40px', null, null, '96px']} {...boxProps}>
      <LayoutGrid overflow="hidden">
        <Box mb={['24px', null, null, '0px']} gridRow={'1'} gridColumn={['1/end', null, null, '1/5']}>
          <H3 as="h2" color="darkGreen" mb={['0px', null, null, '50px']}>
            {sectionTitle}
          </H3>
        </Box>
        <Box gridColumn="1/end" gridRow={'2'}>
          {loading ? (
            <Loading />
          ) : (
            <>
              <Select
                onChange={onSelectDepartment}
                width={['100%', null, null, '354px']}
                maxWidth="100%"
                mb={['40px', null, null, '64px']}
              >
                <option value={ALL_DEPARTMENTS}>{ALL_DEPARTMENTS}</option>
                {allDepartments.map(department => (
                  <option key={department.id} value={department.name}>
                    {department.name}
                  </option>
                ))}
              </Select>
              {departments.map(department => (
                <RolesDepartment
                  key={department.id}
                  department={department}
                  width="100%"
                  mb={['40px', null, null, '50px']}
                />
              ))}
            </>
          )}
          {error && (
            <>
              <Flex justifyContent="center" alignItems="center" flexDirection="column">
                <P0 fontWeight="normal" color="darkestGray">
                  {copy.fetchError}
                </P0>
              </Flex>
              <StyledAnchor
                href={externalUrls.CAREERS}
                onClick={handleTrackEvent('Careers See All')}
                variant="primaryLong"
                gridColumn={['1/end', null, null, '4/end']}
                mt={['NONE', null, null, '50px', null]}
              >
                <P0>{copy.buttonText}</P0>
              </StyledAnchor>
            </>
          )}
        </Box>
      </LayoutGrid>
    </Wrapper>
  );
};
