import React from 'react';

import {Testimonial} from 'src/components/careers';
import {Box, BoxProps, LayoutGrid} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';

import {SanityTestimonials} from 'graphql-types';

const {H3} = Typography;

export const Team: React.FC<SanityTestimonials & {boxProps?: BoxProps}> = props => {
  return (
    <Box backgroundColor="lightBeige" py={['40px', null, null, '90px']} {...props.boxProps}>
      <H3 as="h2" color="darkGreen" mb={['24px', null, null, '72px']} textAlign={'center'}>
        {props.title}
      </H3>
      <LayoutGrid
        alignItems={'center'}
        justifyContent={'center'}
        gridTemplateRows={[null, null, null, '1fr 1fr']}
        gridColumnGap={'48px'}
        gridRowGap={'24px'}
      >
        {props.members?.map((testimonial, index) => {
          const {_key, name, title, quote, icon} = testimonial || {};
          if (name && title && quote && icon?.asset?.url) {
            return (
              <Testimonial
                key={_key}
                name={name}
                title={title}
                image={icon?.asset?.url}
                quote={quote}
                gridRow={[`${index + 1}`, null, null, null, `${index < 2 ? 1 : 2}`]}
                gridColumn={['1/end', null, null, null, (index + 1) % 2 ? '1/4' : '4/end']}
              />
            );
          }
          return null;
        })}
      </LayoutGrid>
    </Box>
  );
};
