import React from 'react';

import {SectionLayout, Perk} from 'src/components/careers';
import {Box, BoxProps} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';

import {SanityBenefits} from 'graphql-types';

const {H3, Body} = Typography;

export const Perks: React.FC<SanityBenefits & {boxProps?: BoxProps}> = props => {
  return (
    <Box pt={['9px', null, null, '40px']} backgroundColor="white" {...props.boxProps}>
      <SectionLayout
        leftContent={
          <>
            <H3 as="h2" color="darkGreen" mb={['16px', null, null, '40px']}>
              {props.title}
            </H3>
            <Body maxWidth={'350px'}>{props.description}</Body>
          </>
        }
        leftWrapperProps={{
          mb: ['32px', null, null, '0px'],
        }}
        rightContent={props.values?.map(benefit => {
          if (benefit?.title && benefit.description) {
            return <Perk key={benefit?._key} header={benefit?.title} description={benefit?.description} />;
          }
          return null;
        })}
      />
    </Box>
  );
};
