import React from 'react';

import {Box, BoxProps, Image, LayoutGrid} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';

import {SanityStory} from 'graphql-types';

const {H3, Body} = Typography;

export const Story: React.FC<SanityStory & {boxProps?: BoxProps}> = props => {
  return (
    <Box
      maxWidth={['calc(100% - 50px)', null, null, 'calc(100% - 150px)', 'calc(100% - 200px)', '1160px']}
      backgroundColor="white"
      py={['42px', null, null, null, '96px']}
      marginX="auto"
      {...props.boxProps}
    >
      <H3 as="h2" color="darkGreen" mb={['24px', null, null, '52px']}>
        {props.title}
      </H3>
      <LayoutGrid
        gridColumnGap={'48px'}
        gridRowGap={['0px', null, null, '32px']}
        gridTemplateRows={[null, null, null, null, '1fr 1fr']}
        maxWidth={'100%'}
      >
        <Box gridRow={['2', null, null, '1']} gridColumn={['1/end', null, null, '1/4']} mb={['40px', null, null, '0']}>
          <Body maxWidth={['100%', null, null, '502px']}>{props.content1}</Body>
        </Box>
        <Box
          gridRow={['1', null, null, '1']}
          gridColumn={['1/end', null, null, '4/end']}
          mb={['16px', null, null, '0']}
        >
          {props.image1?.asset?.url && <Image src={props.image1.asset.url} />}
        </Box>
        <Box gridRow={['3', null, null, '2']} gridColumn={['1/end', null, null, '1/4']} mb={['16px', null, null, '0']}>
          {props.image2?.asset?.url && <Image src={props.image2?.asset?.url} />}
        </Box>
        <Box gridRow={['4', null, null, '2']} gridColumn={['1/end', null, null, '4/end']}>
          <Body maxWidth={['100%', null, null, '446px']}>{props.content2}</Body>
        </Box>
      </LayoutGrid>
    </Box>
  );
};
