import * as externalUrls from 'src/routes/externalUrls';
import useFetch, {Options} from 'use-http';

export type GreenhouseLocation = {
  name: string;
};

export type GreenhouseJob = {
  id: number;
  location: GreenhouseLocation;
  title: string;
  absolute_url: string;
};

export type GreenhouseDepartment = {
  id: number;
  jobs: GreenhouseJob[];
  name: string;
};

export type GreenhouseDepartmentsResponse = {
  departments: GreenhouseDepartment[];
};

export type useFetchGreenhousePositionsReturn = {
  data: GreenhouseDepartmentsResponse['departments'];
  loading: boolean;
  error?: Error;
};

export const useFetchGreenhousePositions = (options?: Options): useFetchGreenhousePositionsReturn => {
  const {data, loading, error} = useFetch<GreenhouseDepartmentsResponse>(externalUrls.CAREERS_API_URL, options, []);
  return {
    data: data?.departments ?? [],
    loading,
    error,
  };
};
