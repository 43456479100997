import React from 'react';

import {Events} from 'src/analytics';
import {ExternalAnchor} from 'src/components/controls/Anchor';
import {Box, BoxProps, Grid, LayoutGrid} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';
import {GreenhouseDepartment, GreenhouseJob} from 'src/hooks/useFetchGreenhousePositions';
import styled from 'styled-components/macro';

const {Small, H5, P0} = Typography;

export type RolesDepartmentProps = BoxProps & {
  department: GreenhouseDepartment;
};

const Wrapper: typeof Box = styled(Box).attrs({})``;

const StyledAnchor = styled(ExternalAnchor)`
  transition: opacity 100ms ease;
  &:hover {
    opacity: 0.75;
  }
`;

export const RolesDepartment: React.FC<RolesDepartmentProps> = ({department, ...props}) => {
  const handleTrackEvent = (job: GreenhouseJob) => () => {
    Events.didClickJob({jobTitle: job.title, jobUrl: job.absolute_url});
  };
  return (
    <Wrapper {...props}>
      <LayoutGrid maxWidth="none" gridRowGap="12px">
        <Box gridColumn={['1/end', null, null, '1/2']}>
          <Small>{department.name}</Small>
        </Box>
        <Box gridColumn={['1/end', null, null, '2/end']} gridRow={['2', null, null, '1']}>
          <Grid gridTemplateColumns={['1fr', null, null, null, null, '1fr 1fr']} gridGap="24px 100px">
            {department.jobs.map(job => (
              <StyledAnchor key={job.id} href={job.absolute_url} onClick={handleTrackEvent(job)} width={null}>
                <Box>
                  <H5 color="darkGreen" mb="8px">
                    {job.title}
                  </H5>
                  <P0 fontWeight="normal" color="darkestGray">
                    {job.location.name}
                  </P0>
                </Box>
              </StyledAnchor>
            ))}
          </Grid>
        </Box>
      </LayoutGrid>
    </Wrapper>
  );
};
