import React from 'react';

import {Box, BoxProps, LayoutGrid, Section} from 'src/components/shared';
import styled from 'styled-components/macro';

export type SectionLayoutProps = BoxProps & {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
  leftWrapperProps?: BoxProps;
  rightWrapperProps?: BoxProps;
};

const Wrapper = styled(Section).attrs({})``;

export const SectionLayout: React.FC<SectionLayoutProps> = ({
  leftContent,
  rightContent,
  leftWrapperProps = {},
  rightWrapperProps = {},
  ...props
}) => {
  return (
    <Wrapper {...props}>
      <LayoutGrid height="100%" gridColumnGap={'48px'}>
        <Box
          mb={['40px', null, null, '0px']}
          gridRow={'1'}
          gridColumn={['1/end', null, null, '1/4']}
          {...leftWrapperProps}
        >
          {leftContent}
        </Box>
        <Box gridRow={['2', null, null, '1']} gridColumn={['1/end', null, null, '4/end']} {...rightWrapperProps}>
          {rightContent}
        </Box>
      </LayoutGrid>
    </Wrapper>
  );
};
