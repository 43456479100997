import React from 'react';

import {SectionLayout} from 'src/components/careers';
import {Box, BoxProps, Grid} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';

import {SanityValues} from 'graphql-types';

const {H3, H5, Small, Body} = Typography;

export const Values: React.FC<SanityValues & {boxProps?: BoxProps}> = props => {
  return (
    <Box pt={['9px', null, null, '40px']} backgroundColor="lightBeige" {...props.boxProps}>
      <SectionLayout
        leftContent={
          <>
            <H3 as="h2" color="darkGreen" mb={['16px', null, null, '25px']}>
              {props.title}
            </H3>
            <Body maxWidth={'350px'}>{props.description}</Body>
          </>
        }
        leftWrapperProps={{
          mb: ['24px', null, null, '0px'],
        }}
        rightContent={
          <Grid
            gridTemplateColumns={['1fr', null, null, '1fr 1fr']}
            gridRowGap={['32px', null, null, '40px']}
            gridAutoFlow={[null, null, null, 'column']}
            gridTemplateRows={[null, null, null, '1fr 1fr 1fr']}
            gridColumnGap={[null, null, null, '16px']}
          >
            {props.values?.map(value => (
              <Box key={value?._key}>
                <Small mb={['9px', null, null, '8px']}>
                  {Number(value?.number).toLocaleString('en-US', {minimumIntegerDigits: 2})}
                </Small>
                <H5
                  fontSize={['1.125rem', null, '1.375rem']}
                  lineHeight={['1.375rem', null, '1.75rem']}
                  color="darkGreen"
                >
                  {value?.value}
                </H5>
              </Box>
            ))}
          </Grid>
        }
      />
    </Box>
  );
};
