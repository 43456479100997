import React from 'react';

import {Events} from 'src/analytics';
import {SectionLayout} from 'src/components/careers';
import {Anchor} from 'src/components/controls';
import {Box, Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';
import * as paths from 'src/routes/paths';
import * as careerCopy from 'src/utils/copy';
import {HEADER_NAV_HEIGHT} from 'src/utils/measurements';
import styled from 'styled-components/macro';

import {SanityCareers} from 'graphql-types';

const {H1, P0, Body} = Typography;

const StyledAnchor = styled(Anchor).attrs({
  variant: 'primary',
  color: 'offBlack',
  width: ['100%', null, '321px'],
})`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 44px;
  max-width: 100%;
`;

export const Header: React.FC<SanityCareers> = props => {
  const copy = careerCopy.careers.header;
  const handleTrackEvent = (item: string) => () => {
    Events.didClickNav({item, pageLocation: 'hero'});
  };
  return (
    <Box backgroundColor="black">
      <SectionLayout
        height={[null, null, null, `calc(100vh - ${HEADER_NAV_HEIGHT.desktop + 40}px)`]}
        minHeight={[null, null, null, '560px']}
        maxHeight={'636px'}
        pt={0}
        pb={['31px', null, '52px', '0']}
        leftContent={
          <Flex
            justifyContent={'center'}
            flexDirection={'column'}
            alignItems={'center'}
            height="100%"
            pt={[null, null, null, '24px']}
            pb={['9px', null, null, '24px']}
          >
            <H1
              fontSize={['2.25rem', null, null, '2.6875rem', '3rem']}
              lineHeight={['2.35rem', null, null, '2.875rem', '3.25rem']}
              mb={['32px', null, null, null, '48px']}
              fontWeight={'normal'}
              color="lightGold"
            >
              {props.heading}
            </H1>
            <Body maxWidth={'700px'} mb={['32px', null, null, null, '48px']} color={'white'}>
              {props.content}
            </Body>
            <StyledAnchor href={`#${paths.careerAnchorPaths.roles}`} onClick={handleTrackEvent('Careers Roles')}>
              <P0>{props.buttonText}</P0>
            </StyledAnchor>
          </Flex>
        }
        leftWrapperProps={{
          mb: '0px',
          mx: '10px',
          gridColumn: '1/end',
          textAlign: 'center',
        }}
        rightWrapperProps={{
          position: 'relative',
          height: ['252px', null, null, '100%'],
          gridRow: '1',
        }}
        rightContent={<Box></Box>}
      />
    </Box>
  );
};
