import React from 'react';

import {Box, BoxProps} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';
import styled from 'styled-components/macro';

const {H5, P0} = Typography;

const Wrapper: typeof Box = styled(Box).attrs({})``;

export const Perk: React.FC<{header: string; description: string} & BoxProps> = ({header, description, ...props}) => {
  return (
    <Wrapper mb={['32px', null, null, '53px']} {...props}>
      <H5
        fontSize={['1.125rem', null, '1.375rem']}
        lineHeight={['1.375rem', null, '1.75rem']}
        color="darkGreen"
        mb="16px"
      >
        {header}
      </H5>
      <P0 fontWeight="normal">{description}</P0>
    </Wrapper>
  );
};
